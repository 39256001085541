import React from 'react'
import Style from '../../styles.module.css'
import {AnalysisType} from '../../types/types'

const analysisTypes: AnalysisType[] = [
  {name: 'Normal', image: '/c9/normal.png'},
  {name: 'Cross Polarization', image: '/c9/cross.png'},
  {name: 'Parallel Polarization', image: '/c9/parallel.png'},
  {name: 'UV', image: '/c9/uv.png'},
]

const Features: React.FC = () => {
  return (
    <section className={Style.features}>
      <div className='container'>
        <div className='d-none d-md-block'>
          <div className={Style.featureText}>
            <div className=''>
              <p>Хиймэл оюун ухаан</p>
              <h2>17 жилийн</h2>
              <p>судалгаатай их өгөгдлийн системээс шинжлэн гаргаж ирдэг.</p>
            </div>
            <div className={Style.separator} />
            <div className=''>
              <p>Онцлог</p>
              <h2>4 төрлийн</h2>
              <p>гэрлийн тусламжтайгаар арьсны өөр өөр асуудлуудыг оношилно.</p>
            </div>
          </div>
        </div>
        <div className='d-block d-md-none'>
          <p>Хиймэл оюун ухаан</p>
          <h2>17 жилийн</h2>
          <p>судалгаатай их өгөгдлийн системээс шинжлэн гаргаж ирдэг.</p>
        </div>
        <div className={Style.analysticImages}>
          {analysisTypes.map((type, index) => (
            <div key={index}>
              <img src={type.image} alt={type.name} />
            </div>
          ))}
        </div>
        <div className='d-block d-md-none'>
          <p>Онцлог</p>
          <h2>4 төрлийн</h2>
          <p>гэрлийн тусламжтайгаар арьсны өөр өөр асуудлуудыг оношилно.</p>
        </div>
      </div>
    </section>
  )
}

export default Features
