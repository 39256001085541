import React, {useEffect, useState} from 'react'
import {BookingCard} from '../../BookingCard'
import {useCalendarData} from '../../core/CalendarDataProvider'
import {Membership} from '../../core/_models'
import Style from './styles.module.css'
import {CRUD_RESPONSES, ID} from '../../../../../_metronic/helpers'
import {bookingAppointment, getCouponCheck} from '../../core/_requests'
import {useCalendarItem} from '../../core/CalendarItemProvider'
import {ErrorAlert} from '../../../../../_metronic/helpers/alerts/Error'
import {WarningAlert} from '../../../../../_metronic/helpers/alerts/Warning'
import {useNavigate} from 'react-router-dom'

const BookingNoteC9Body = () => {
  const {memberships, onlineBookingSettings} = useCalendarData()
  const navigate = useNavigate()
  const {itemDatas, setItemDatas} = useCalendarItem()
  const [membershipId, setMembershipId] = useState<ID>(null)
  const [membershipNote, setMembershipNote] = useState<string | undefined>(undefined)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [couponCode, setCouponCode] = useState<string | undefined>(undefined)
  const [couponRate, setCouponRate] = useState<number | undefined>(undefined)
  const [success, setSuccess] = useState<boolean>(false)
  const [pending, setPending] = useState<boolean>(false)
  const [pendingCoupon, setPendingCoupon] = useState<boolean>(false)
  const [finalPayment, setFinalPayment] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (itemDatas.discount) setCouponRate(itemDatas.discount)
    if (itemDatas.membership_id) setMembershipId(itemDatas.membership_id)
    if (itemDatas.coupon_code) setCouponCode(itemDatas.coupon_code)
    if (itemDatas.payable) setFinalPayment(itemDatas.payable)
  }, [])

  useEffect(() => {
    if (couponCode) {
      setIsDisabled(false)
      setItemDatas({
        ...itemDatas,
        membership_id: membershipId,
        coupon_code: couponCode,
        discount: couponRate,
      })
    } else {
      setIsDisabled(true)
      setItemDatas({
        ...itemDatas,
        payable: itemDatas.total_payment,
        membership_id: null,
        coupon_code: undefined,
        discount: undefined,
      })
    }
  }, [couponCode])

  useEffect(() => {
    if (membershipId) {
      setCouponRate(undefined)
      setCouponCode('')
      setFinalPayment(itemDatas.payable)
      setSuccess(false)
    }
  }, [membershipId])

  useEffect(() => {
    if (finalPayment) {
      setItemDatas({...itemDatas, payable: finalPayment})
    }
  }, [finalPayment])

  const handleCouponCheck = async () => {
    setPendingCoupon(true)
    const requestData = {
      membership_id: membershipId,
      code: couponCode,
    }
    const response = await getCouponCheck(requestData)
    if (response.statusCode === 200) {
      setCouponRate(response.data)
      setFinalPayment(
        itemDatas.total_payment ? (itemDatas.total_payment * (100 - response.data)) / 100 : 0
      )
      setSuccess(true)
      setPendingCoupon(false)
    } else {
      WarningAlert('Купон код буруу байна.')
      setCouponRate(undefined)
      setFinalPayment(itemDatas.total_payment)
      setSuccess(false)
      setPendingCoupon(false)
    }
  }
  
  let createSuccess = 0
  const handleSubmit = async () => {
    setPending(true)
    // console.log(itemDatas)
    try {
      const response = await bookingAppointment(itemDatas)
      const status = response.payload?.status
      setItemDatas({...itemDatas, id: response.data?.id})
      if (status && status === 200) {
        createSuccess = 200
      }
      // status && status === 201 && ErrorAlert(CRUD_RESPONSES.role_limit)
      if (status && status === 202) {
        createSuccess = 202
      }
    } catch (ex: any) {
      console.error(ex)
      ex.response?.status === 403
        ? WarningAlert(CRUD_RESPONSES.failed_authorization)
        : ErrorAlert(CRUD_RESPONSES.error)
    } finally {
      setPending(false)
      if (createSuccess === 200) {
        createSuccess = 0
        navigate('/booking/payment')
      } else if (createSuccess === 202) {
        WarningAlert('Уучлаарай! Тухайн цаг дээр захиалга хийгдсэн тул өөр цаг сонгоно уу.')
      } else {
        ErrorAlert('Түр хүлээгээд дахин оролдоно уу!!!')
      }
    }
  }

  return (
    <div className={Style.parentContainer}>
      <div className={Style.mainContainer}>
        <div className={Style.membershipContainer}>
          <p className='fs-4' style={{fontWeight: 500}}>
            Хөнгөлөлт ашиглах
          </p>
          <div className={Style.membershipBtnContainer}>
            {memberships?.map((membership: Membership, index: number) => {
              return (
                <button
                  key={index}
                  className={`${Style.membershipButton} ${
                    membershipId === membership.id ? Style.active : ''
                  }`}
                  onClick={() => {
                    setMembershipId(membership.id)
                    setMembershipNote(membership.note)
                  }}
                >
                  {membership.title}
                </button>
              )
            })}
          </div>
          {membershipNote && (
            <p style={{fontSize: 11, marginTop: 10, textAlign: 'justify'}}>
              {membershipNote}
            </p>
          )}

          {membershipId && (
            <div className='d-flex gap-3 mt-5'>
              <input
                type='text'
                value={couponCode}
                className={Style.couponInput}
                placeholder='Купон код'
                onChange={(e) => setCouponCode(e.target.value)}
              />
              {pendingCoupon ? (
                <div className={`${Style.couponButton}`}>
                  <div className={Style.pendingBtn}></div>
                </div>
              ) : (
                <button
                  disabled={isDisabled}
                  className={Style.couponButton}
                  onClick={handleCouponCheck}
                >
                  Шалгах
                </button>
              )}
            </div>
          )}
        </div>
        {itemDatas && (
          <div className={Style.paymentInfo}>
            <p className='fs-4' style={{fontWeight: 500}}>
              Төлбөрийн мэдээлэл
            </p>
            <div className={Style.paymentInfoContainer}>
              <div>
                <p>Нийт үнийн дүн</p>
                <p>{itemDatas.total_payment?.toLocaleString('en')} ₮</p>
              </div>
              <div>
                <p>Байгууллагын хямдрал</p>
                <p>
                  {success && <span>-</span>}
                  {itemDatas.total_payment && couponRate !== undefined
                    ? ((itemDatas.total_payment * couponRate) / 100).toLocaleString('en')
                    : '0'}{' '}
                  ₮
                </p>
              </div>
              <div>
                <p>Нийт төлөх дүн</p>
                <p>{itemDatas.payable?.toLocaleString('en')} ₮</p>
              </div>
            </div>
            {pending ? (
              <button>Уншиж байна...</button>
            ) : (
              <button onClick={handleSubmit}>Төлбөр төлөх</button>
            )}
          </div>
        )}
      </div>
      {onlineBookingSettings.important_info && (
        <div className={Style.noteContainer}>
          <h5>Санамж:</h5>
          <div dangerouslySetInnerHTML={{__html: onlineBookingSettings.important_info}} />
        </div>
      )}
    </div>
  )
}

export default function NoteC9() {
  return <BookingCard title='Баталгаажуулах' body={<BookingNoteC9Body />} />
}
