import React from 'react'
import Style from '../../styles.module.css'
const characteristics = [
  'Арьсны төрөл',
  'Нүхжилт',
  'Үрчлээ',
  'Уян хатан чанар',
  'Нөсөө толбо',
  'Тольгор байдал',
  'Арьсны өнгө',
  'Улайлт',
  'Гэрэлтэх байдал',
  'Бохирдол',
  'Чийгшил',
  'Тослогжилт',
]

const Characteristics: React.FC = () => {
  return (
    <section className={` ${Style.characteristics}`}>
      <div className='container'>
        <div className={Style.cardContainer}>
          <div className={Style.featureCard}>
            <div>
              <img src='/c9/card1.png' alt='card1' />
            </div>
            <p> Хиймэл оюун ухаанд суурилсан ИX ДАТА дээр үндэслэн хариуг гаргадаг.</p>
          </div>
          <div className={Style.featureCard}>
            <div>
              <img src='/c9/card2.png' alt='card2' />
            </div>
            <p>Арьсны бүтцийг 3 хэмжээст хувилбараар харах боломжтой.</p>
          </div>
          <div className={Style.featureLists}>
            <h4 className='text-center mb-10'>12 төрлийн оношилгоо</h4>
            <div className={Style.badgeContainer}>
              {characteristics.map((item, index) => {
                if (index < 2)
                  return (
                    <p key={index} className={Style.badge}>
                      <i className='bi bi-diamond-fill text-dark opacity-75 me-2'></i>
                      {item}
                    </p>
                  )
              })}
            </div>
            <div className={Style.badgeContainer}>
              {characteristics.map((item, index) => {
                if (index >= 2 && index < 4)
                  return (
                    <p key={index} className={Style.badge}>
                      <i className='bi bi-diamond-fill text-dark opacity-75 me-2'></i>
                      {item}
                    </p>
                  )
              })}
            </div>
            <div className={Style.badgeContainer}>
              {characteristics.map((item, index) => {
                if (index >= 4 && index < 6)
                  return (
                    <p key={index} className={Style.badge}>
                      <i className='bi bi-diamond-fill text-dark opacity-75 me-2'></i>
                      {item}
                    </p>
                  )
              })}
            </div>
            <div className={Style.badgeContainer}>
              {characteristics.map((item, index) => {
                if (index >= 6 && index < 8)
                  return (
                    <p key={index} className={Style.badge}>
                      <i className='bi bi-diamond-fill text-dark opacity-75 me-2'></i>
                      {item}
                    </p>
                  )
              })}
            </div>
            <div className={Style.badgeContainer}>
              {characteristics.map((item, index) => {
                if (index >= 8 && index < 10)
                  return (
                    <p key={index} className={Style.badge}>
                      <i className='bi bi-diamond-fill text-dark opacity-75 me-2'></i>
                      {item}
                    </p>
                  )
              })}
            </div>
            <div className={Style.badgeContainer}>
              {characteristics.map((item, index) => {
                if (index >= 10 && index < 12)
                  return (
                    <p key={index} className={Style.badge}>
                      <i className='bi bi-diamond-fill text-dark opacity-75 me-2'></i>
                      {item}
                    </p>
                  )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Characteristics
