import React from 'react'
import Style from '../../styles.module.css'
import {Image} from 'react-bootstrap'

const Header: React.FC = () => {
  return (
    <header className={Style.header}>
      <div className='container'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className={Style.logo}>
            <Image src='/c9/logo.svg' alt='logo' className={Style.logo} />
          </div>
          <div className={Style.socialLinks}>
            <a href='#' className={Style.socialIcon}>
              <i className='bi bi-facebook fs-6 text-white'></i>
            </a>
            <a href='#' className={Style.socialIcon}>
              <i className='bi bi-instagram fs-6 text-white'></i>
            </a>
            <a
              href='https://cloudnine.mn/'
              target='_blank'
              className={`${Style.btn} ${Style.btnHeader}`}
            >
              cloudnine.mn
            </a>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
