import React from 'react'
import Style from '../../styles.module.css'
import {Image} from 'react-bootstrap'
const Footer: React.FC = () => {
  return (
    <footer>
      <div className='container'>
        <div className={Style.footer}>
          <div className={Style.logo}>
            <Image src='/c9/logo.svg' alt='logo' className={Style.logo} />
          </div>
          <div className='d-flex gap-6 justify-content-center'>
            <a href='tel:77552323' className='text-dark text-decoration-none'>
              7755-2323
            </a>
            <a href='mailto:info@cloudnine.mn' className='text-dark text-decoration-none'>
              info@cloudnine.mn
            </a>
          </div>
          <div className={Style.footerSocialLink}>
            <a href='#' className='text-light me-3'>
              <i className='bi bi-facebook'></i>
            </a>
            <a href='#' className='text-light me-3'>
              <i className='bi bi-instagram'></i>
            </a>
            <a href='#' className='text-light'>
              <i className='bi bi-youtube'></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
