import {FC} from 'react'
import {WelcomePageTheme1} from './WelcomePageTheme1'
import WelcomePageC9 from './cloudnine/WelcomePageC9'

export const WelcomePage: FC = () => {
  const appName = process.env.REACT_APP_NAME
  return <WelcomePageC9 />
  // if (appName && appName === 'Cloud Nine') return <WelcomePageC9 />
  // else return <WelcomePageTheme1 />
}
