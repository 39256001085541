import React from 'react'
import Style from '../../styles.module.css'
import {Image} from 'react-bootstrap'
const Process: React.FC = () => {
  return (
    <section className={Style.process}>
      <div className='container'>
        <div className={Style.processCard}>
          <div>
            <div className={Style.iconContainer}>
              <Image src='/c9/icon1.png' alt='icon' />
            </div>
            <h3>Үзлэгийн өмнөх бэлтгэл</h3>
            <p>
              Үйлчлүүлэгч үзлэгийн цагаас 10-20 минутын өмнө ирж арьсаа бэлдэх хэрэгтэй. Учир нь
              гадаад орчны нөлөө (цаг агаар)-с шалтгаалаад арьс хуурайших, цочрох, даарах тохиолдолд
              оношилгооны хариу зөрүүтэй гарах магадлалтай юм. <br />
              <br /> *Та ирэхээсээ өмнө будгаа арилгаад ирж болно. Эсвэл бидний бэлтгэсэн
              бүтээгдэхүүнийг хэрэглэн нүүрний будгаа арилгаж болно.
            </p>
          </div>

          <div>
            <div className={Style.iconContainer}>
              <Image src='/c9/icon2.png' alt='icon' />
            </div>
            <h3>Үзлэгийн хугацаа</h3>
            <p>
              Үзлэгийн нийт үргэлжлэх хугацаа <strong>25 минут</strong> болно. Үүнд:
            </p>
            <ul>
              <li> Тусгай төхөөрөмжөөр арьс оношилгоо</li>
              <li> Арьс арчилгааны зөвлөгөө</li>
              <li> Бүтээгдэхүүний зөвлөгөө тус тус багтсан.</li>
            </ul>
          </div>

          <div>
            <div className={Style.iconContainer}>
              <Image src='/c9/icon3.png' alt='icon' />
            </div>
            <h3>Дэлгэрэнгүй үзлэг</h3>
            <p>
              6 долоо хоногийн дараах хоёр дахь давтан үзлэг нь төлбөртэй. Хэрэглэгч цаг захиалгын
              хуудасруу орж төлбөрөө төлж цагаа баталгаажуулна. <br />
              <br />
              Харин та давтан үзлэг хийлгэх өдрөө 100,000₮-с дээш худалдан авалт хийсэн тохиолдолд
              давтан үзлэгийн төлбөрийг 100% хөнгөлж худалдан авалтын дүнгээс хасна. Үзлэгийн
              e-barimt тусдаа хэвлэгдэж өгнө.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Process
