import React from 'react'
import Features from './components/Features/Features'
import Characteristics from './components/Characteristics/Characteristics'
import Process from './components/Process/Process'
import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
import Hero from './components/Hero/Hero'
import Style from './styles.module.css'

function WelcomePageC9() {
  return (
    <div className={Style.mainContainer}>
      <div className={Style.defaultWidth}>
        <Header />
        <Hero />
        <Features />
        <Characteristics />
        <Process />
        <Footer />
      </div>
    </div>
  )
}

export default WelcomePageC9
