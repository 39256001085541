import React from 'react'
import Style from '../../styles.module.css'
import {Image} from 'react-bootstrap'
import {Link} from 'react-router-dom'
const Hero: React.FC = () => {
  return (
    <section className={`py-5 ${Style.hero}`}>
      <div className='container'>
        <div className={Style.heroText}>
          <div>
            <h1>My Beauty Rituals Ганцаарчилсан Арьс Оношилгоо</h1>
            <Image src='/c9/device.png' alt='device img' className='d-block d-md-none mb-4' />
            <p>
              “My Beauty Rituals” нь зөвхөн өөртөө тохирсон арьс арчилгаа, гоо сайхны дэглэмтэй
              болохыг хүсэж байгаа хүн бүхэнд зориулсан 6 долоо хоногийн хөтөлбөр юм.
            </p>
            <Link to='/booking/service' className={Style.btn}>
              Цаг авах
            </Link>
          </div>
          <div className='d-none d-md-block'>
            <Image src='/c9/device.png' alt='device img' />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
